// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.nav-bar-page {
    background-color: black;
}
.logo-image {
    max-width: 40px; /* Set the maximum width of the image */
    max-height: 40px; /* Set the maximum height of the image */
    width: auto;
    height: auto;
    margin-left: 100px;
    border-radius: 50%;
}

.navbar-text {
    position: relative;
    display: inline-block;
    margin-right: 20px; /* Adds space between each nav item */
}

.navbar-text p {
    margin: 0;
    color: white; /* Set the text color to white */
    font-weight: 500;
    position: relative;
    z-index: 1; /* Ensures the text is above the circle */
    transition: color 0.3s ease; /* Smooth color transition */
}

.navbar-text::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: white;
    border-radius: 50%;
    transition: width 0.3s ease, height 0.3s ease, top 0.3s ease, left 0.3s ease;
    z-index: 0; /* Circle stays behind the text */
    transform: translate(-50%, -50%);
}

.navbar-text:hover::before {
    width: 100%;
    height: 100%;
}

.navbar-text:hover p {
    color: black; /* Change text color to black on hover */
}


`, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;AAC3B;AACA;IACI,eAAe,EAAE,uCAAuC;IACxD,gBAAgB,EAAE,wCAAwC;IAC1D,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB,EAAE,qCAAqC;AAC7D;;AAEA;IACI,SAAS;IACT,YAAY,EAAE,gCAAgC;IAC9C,gBAAgB;IAChB,kBAAkB;IAClB,UAAU,EAAE,yCAAyC;IACrD,2BAA2B,EAAE,4BAA4B;AAC7D;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,QAAQ;IACR,SAAS;IACT,uBAAuB;IACvB,kBAAkB;IAClB,4EAA4E;IAC5E,UAAU,EAAE,iCAAiC;IAC7C,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY,EAAE,wCAAwC;AAC1D","sourcesContent":["\r\n.nav-bar-page {\r\n    background-color: black;\r\n}\r\n.logo-image {\r\n    max-width: 40px; /* Set the maximum width of the image */\r\n    max-height: 40px; /* Set the maximum height of the image */\r\n    width: auto;\r\n    height: auto;\r\n    margin-left: 100px;\r\n    border-radius: 50%;\r\n}\r\n\r\n.navbar-text {\r\n    position: relative;\r\n    display: inline-block;\r\n    margin-right: 20px; /* Adds space between each nav item */\r\n}\r\n\r\n.navbar-text p {\r\n    margin: 0;\r\n    color: white; /* Set the text color to white */\r\n    font-weight: 500;\r\n    position: relative;\r\n    z-index: 1; /* Ensures the text is above the circle */\r\n    transition: color 0.3s ease; /* Smooth color transition */\r\n}\r\n\r\n.navbar-text::before {\r\n    content: '';\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    width: 0;\r\n    height: 0;\r\n    background-color: white;\r\n    border-radius: 50%;\r\n    transition: width 0.3s ease, height 0.3s ease, top 0.3s ease, left 0.3s ease;\r\n    z-index: 0; /* Circle stays behind the text */\r\n    transform: translate(-50%, -50%);\r\n}\r\n\r\n.navbar-text:hover::before {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.navbar-text:hover p {\r\n    color: black; /* Change text color to black on hover */\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
