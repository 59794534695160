
import 'bootstrap/dist/css/bootstrap.min.css';
import facebook from './picture/facebook-app-symbol.png'
import insta from './picture/instagram.png'
import github from './picture/github.png'
import './media.css'
import Container from 'react-bootstrap/Container';
function App() {
  return (
   

        <div className='media-page'>
            <a href="https://www.facebook.com/kidsfunpro" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="Facebook logo" className="logo" />
            </a>

            <a href="https://www.instagram.com/hoi_phung/" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="Instagram logo" className="logo" />
            </a>

            <a href="https://github.com/hhphung" target="_blank" rel="noopener noreferrer">
                <img src={github} alt="GitHub logo" className="logo" />
            </a>
        </div>



       
  );
}

export default App;
