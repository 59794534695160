// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-me-page{
    margin-top: 5%;
    margin-bottom: 5%;
}

.aboutmetext {
margin-left: 5%;
margin-right: 5%;
border-radius: 8px;
border-style: solid;
box-sizing: border-box;
font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
font-size: 16px;
font-weight: 500;
text-align: center;
text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
background-color: rgb(223, 223, 223);
}

`, "",{"version":3,"sources":["webpack://./src/Aboutme.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;AACA,eAAe;AACf,gBAAgB;AAChB,kBAAkB;AAClB,mBAAmB;AACnB,sBAAsB;AACtB,2JAA2J;AAC3J,eAAe;AACf,gBAAgB;AAChB,kBAAkB;AAClB,0CAA0C;AAC1C,oCAAoC;AACpC","sourcesContent":[".about-me-page{\r\n    margin-top: 5%;\r\n    margin-bottom: 5%;\r\n}\r\n\r\n.aboutmetext {\r\nmargin-left: 5%;\r\nmargin-right: 5%;\r\nborder-radius: 8px;\r\nborder-style: solid;\r\nbox-sizing: border-box;\r\nfont-family: \"Inter UI\",\"SF Pro Display\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Oxygen,Ubuntu,Cantarell,\"Open Sans\",\"Helvetica Neue\",sans-serif;\r\nfont-size: 16px;\r\nfont-weight: 500;\r\ntext-align: center;\r\ntext-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;\r\nbackground-color: rgb(223, 223, 223);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
