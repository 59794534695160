import './Aboutme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import self from './picture/graduate.jpg';

function App() {
  return (
    <Container className='about-me-page'>
      <Row className="align-items-center">
    


        <Col >
          <div className="aboutmetext">
            <h2> Hi</h2>
            <h2> My name is Hoi Phung.</h2>
            <p>
              "I graduated in December 2022 from Iowa State University, majoring in computer science. 
              I’m a person who likes solving problems and building things. That's why I studied computer science, it fits my lifestyle.
            </p>
            <p>
              From my last role, I was a computer technician for ISU surplus, working mostly with hardware. 
              I also had an internship at Iowa Veteran Home as IT administrator. Back in my community college, 
              I was an ESL tutor, working with students who learn English as a second language.
            </p>
            <p>
              I’m good with Java, and I used it for most projects during my school. In my free time, I like to go for a walk or play sports.
              Currently working as an IT role at North Liberty City."
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
