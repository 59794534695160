// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
    background-color: #FCFAEE;
    height: 500px;
    width: 100%;
}
.home-text{
    position: absolute;
    margin-top:50px;
    margin-left: 200px;
}
h1{
    font-size: 300%;
}
.home-avatar{
  margin-left: 60%;
  margin-top: 10px;
  border-radius: 50%;
  width: 25%;
  height: 25%;

}

/* CSS */
.about-me-button {
  background-image: linear-gradient(92.88deg, #FFB0B0 9.16%, #FFECC8 43.89%, #FFD09B 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  
}

.about-me-button:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}

@media (min-width: 768px) {
  .about-me {
    padding: 0 2.6rem;
  }
}

`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,WAAW;;AAEb;;AAEA,QAAQ;AACR;EACE,0FAA0F;EAC1F,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,cAAc;EACd,2JAA2J;EAC3J,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,0BAA0B;;AAE5B;;AAEA;EACE,6CAA6C;EAC7C,wBAAwB;AAC1B;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".home-page {\r\n    background-color: #FCFAEE;\r\n    height: 500px;\r\n    width: 100%;\r\n}\r\n.home-text{\r\n    position: absolute;\r\n    margin-top:50px;\r\n    margin-left: 200px;\r\n}\r\nh1{\r\n    font-size: 300%;\r\n}\r\n.home-avatar{\r\n  margin-left: 60%;\r\n  margin-top: 10px;\r\n  border-radius: 50%;\r\n  width: 25%;\r\n  height: 25%;\r\n\r\n}\r\n\r\n/* CSS */\r\n.about-me-button {\r\n  background-image: linear-gradient(92.88deg, #FFB0B0 9.16%, #FFECC8 43.89%, #FFD09B 64.72%);\r\n  border-radius: 8px;\r\n  border-style: none;\r\n  box-sizing: border-box;\r\n  color: #FFFFFF;\r\n  cursor: pointer;\r\n  flex-shrink: 0;\r\n  font-family: \"Inter UI\",\"SF Pro Display\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Oxygen,Ubuntu,Cantarell,\"Open Sans\",\"Helvetica Neue\",sans-serif;\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  height: 4rem;\r\n  padding: 0 1.6rem;\r\n  text-align: center;\r\n  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;\r\n  transition: all .5s;\r\n  user-select: none;\r\n  -webkit-user-select: none;\r\n  touch-action: manipulation;\r\n  \r\n}\r\n\r\n.about-me-button:hover {\r\n  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;\r\n  transition-duration: .1s;\r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .about-me {\r\n    padding: 0 2.6rem;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
