import './App.css';
import Navbar from './Navbar.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home.js'
import About from './Aboutme.js'
import Contact from './contact.js'
import Media from './media.js'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 


function App() {
  return (
    <Router> 
      <div className='App'>
        <Navbar />
        <Home />
        <About />
        <Contact />
        <Media />
      </div>
    </Router>
  );
}

export default App;
