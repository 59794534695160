import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from './picture/letter-h.png';
import './Navbar.css';
function NavBar() {
  return (
    <div className='nav-bar-page'>
    <Navbar >
    
      <Navbar.Brand href="/">   <img src={logo} alt='Logo' className='logo-image' /></Navbar.Brand>
      <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-center">
            <Nav  defaultActiveKey="/home">
                <Nav.Item>
                    <Nav.Link href="/" className='navbar-text'> <p>Home</p> </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/" className='navbar-text'><p>About</p></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/" className='navbar-text'><p>Skill</p></Nav.Link>
                </Nav.Item>
            </Nav>
      </Navbar.Collapse>
  </Navbar>
  </div>
  );
}

export default NavBar;