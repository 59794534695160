import './contact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import emailjs from 'emailjs-com';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    note: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email field is not empty
    if (!formData.email) {
      setErrorMessage('Email is required.');
      return;
    }

    setErrorMessage(''); // Clear any previous error message

    const templateParams = {
      name: formData.name,
      email: formData.email,
      note: formData.note,
    };

    // EmailJS sending email
    emailjs
      .send(
        'service_rwcau0c', // replace with your EmailJS service ID
        'template_pvxsxfc', // replace with your EmailJS template ID
        templateParams,
        '6U8J1PEi_KbfkrCvl' // replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Email sent successfully!');
        },
        (error) => {
          console.log('FAILED...', error);
          alert('Failed to send email. Please try again later.');
        }
      );
  };

  return (
    <Container className='contact-me-page'>
      <h1>Contact me</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Form.Group>

        {errorMessage && (
          <p style={{ color: 'red' }}>{errorMessage}</p>
        )}

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Note"
            name="note"
            value={formData.note}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="dark" type="submit">Send</Button>
      </Form>
    </Container>
  );
}

export default App;
