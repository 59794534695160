// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; /* Ensures that items are in a row */
  gap: 20px; /* Adds spacing between the logos */
  height: 70px; /* Centers the content vertically */
  background-color: rgb(221, 220, 220);
}

.logo {
  width: 50px; /* Adjust as needed for the size of the logos */
  height: 50px;
  
}
`, "",{"version":3,"sources":["webpack://./src/media.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAE,oCAAoC;EACzD,SAAS,EAAE,mCAAmC;EAC9C,YAAY,EAAE,mCAAmC;EACjD,oCAAoC;AACtC;;AAEA;EACE,WAAW,EAAE,+CAA+C;EAC5D,YAAY;;AAEd","sourcesContent":[".media-page {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: row; /* Ensures that items are in a row */\n  gap: 20px; /* Adds spacing between the logos */\n  height: 70px; /* Centers the content vertically */\n  background-color: rgb(221, 220, 220);\n}\n\n.logo {\n  width: 50px; /* Adjust as needed for the size of the logos */\n  height: 50px;\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
