import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import avatar from './picture/hoi.jpg';
import './Home.css';
import React, { useEffect, useState } from 'react';

function App() {
    const name = "Hoi Phung";
    const job = ["Network", "Software", "Data"];
    const [displayedJob, setDisplayedJob] = useState("");
    const [displayIndex, setJobIndex] = useState(0);   // Tracks the character position of the current job being displayed. 
    const [typingIndex, setCharIndex] = useState(0);   //Tracks the character position of the current job being typed.
    const [isDeleting, setIsDeleting] = useState(false);
    useEffect(() => {
        const handleTyping = () => {
            const currentJob = job[displayIndex];
            if (isDeleting) {
                if (typingIndex > 0) {
                    setCharIndex(prev => prev - 1);
                    setDisplayedJob(currentJob.substring(0, typingIndex - 1));
                } else {
                    setIsDeleting(false);
                    setJobIndex((prev) => (prev + 1) % job.length);
                }
            } else {
                if (typingIndex < currentJob.length) {
                    setDisplayedJob(currentJob.substring(0, typingIndex + 1));
                    setCharIndex(prev => prev + 1);
                } else {
                    setTimeout(() => setIsDeleting(true), 1000);
                }
            }
        };

        const typingInterval = setInterval(handleTyping, isDeleting ? 50 : 150);
        return () => clearInterval(typingInterval);
    }, [typingIndex, isDeleting, job, displayIndex]);

    return (
        <div className='home-page'>
            <div className='home-text'>
                <h1>
                    Hi There,
                    I'm {name}
                </h1>
                <h2>
                    I'm interested in <span className="typing">{displayedJob}</span>
                </h2>
                <button className="about-me-button" role="button">About Me</button>
            </div>
            <div>
                <img src={avatar} alt='Avatar' className='home-avatar' />
            </div>
        </div>
    );
}

export default App;
